import {
    AdditionalPracticeFormFieldEnum,
    PracitceAddressForm,
    PracticeFormFieldEnum,
    ProfileFormFieldEnum,
    ServicesFormFieldEnum,
    StepValue,
} from 'pages/CreateUniProfilePage/types';
import {
    ProviderChannelHealthcareExperience,
    ProviderChannelEducation,
    IProfileFormSubmit,
} from 'types/channelApplicationProvider';
import dayjs from 'dayjs';
import { transferServerLicenseTypeToFormData, LicenseData, PhysicianData } from 'data/provider';
import { Channel, EChannelActiveStatus, ELicenseType, EListStatus, License, Physician, Service } from 'types/common';
import { ICommonFormData, ProviderLicenseFromData, ProviderLicenseFromDataWithoutState, ProviderPhysicianFromData, ServiceExt, UniprofileFormData } from 'types/provider';
import { isLicenseExpired, removeHMSInUTCTime } from 'utils/common';
import { FormLicense } from 'types/form';
import { INSURANCE_TYPE_CASH, INSURANCE_TYPE_CASH_AND_INSURANCE } from 'constants/common';
import { ServiceFormFieldEnum } from 'components/ServiceUpdateModal/types';

export const transferFormValue = (formValue: Partial<IProfileFormSubmit>, step: StepValue) => {
    if (step === StepValue.PROFILE) {
        const { foreignLanguage } = formValue;

        return {
            ...formValue,
            foreignLanguage: foreignLanguage instanceof Array ? foreignLanguage.join(',') : foreignLanguage,
        };
    }
    if (step === StepValue.ADDITIONAL_INFORMATION) {
        return {
            ...formValue,
            publicationAdditionalList: formValue.publicationAdditionalList?.filter((item) => !!(item.id || item.additionalType || item.name || item.website || item.authors)) || [],
            associationAdditionalList: formValue.associationAdditionalList?.filter((item) => !!(item.id || item.additionalType || item.name || item.website || item.authors)) || [],
            awardAdditionalList: formValue.awardAdditionalList?.filter((item) => !!(item.id || item.additionalType || item.name || item.website || item.authors)) || [],
        };
    }
    return formValue;
};

const getNurseLicenseList = (licenseDtoList: License[] = []) => {
    const nurce = licenseDtoList?.find((item) => { return item.type === ELicenseType.NURSE && !isLicenseExpired(item); });

    if (nurce) {
        const typeList = Object.keys(transferServerLicenseTypeToFormData(nurce.licenseType) || {});

        return typeList;
    }

    return [];
};

export const transferLicenseToFormData = (licenses: License[]): ProviderLicenseFromDataWithoutState => {
    const ret: ProviderLicenseFromDataWithoutState = {};
    const deasList: FormLicense[] = [];
    const cscList: FormLicense[] = [];
    const cmecList: FormLicense[] = [];
    const practiceList: FormLicense[] = [];

    licenses?.forEach((item) => {
        if (item.type === ELicenseType.DEA) {
            deasList.push(LicenseData.serverToForm(item));
        } else if (item.type === ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION) {
            cscList.push(LicenseData.serverToForm(item));
        } else if (item.type === ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES) {
            cmecList.push(LicenseData.serverToForm(item));
        } else if (item.type === ELicenseType.MIC) {
            //just skip, coz mic don't have state
        } else {
            const add = LicenseData.serverToForm(item);
            if (!add.url && item.licenseType) {
                let infoStr = item.licenseType;
                if (item.licenseType?.includes('http') && item.licenseType?.includes(',')) {
                    infoStr = item.licenseType.split(',')[0];
                }
                if (infoStr?.includes('=')) {
                    add.url = infoStr.split('=')[1];
                } else if (infoStr?.includes('http')) {
                    add.url = infoStr;
                }
            }
            practiceList.push(add);
        }
    });
    if (deasList && deasList.length > 0) {
        ret[ELicenseType.DEA] = deasList;
    }
    if (cscList && cscList.length > 0) {
        ret[ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION] = cscList;
    }
    if (cmecList && cmecList.length > 0) {
        ret[ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES] = cmecList;
    }
    if (practiceList && practiceList.length > 0) {
        ret[ELicenseType.PRACTICE] = practiceList;
    }

    return ret;
};

export const transferLicenseToFormDataArray = (licenses: License[]): ProviderLicenseFromData[] => {
    const states: string[] = [];
    const ret: ProviderLicenseFromData[] = [];
    licenses?.forEach((item: License) => {
        if (!states.includes(item.state)) {
            states.push(item.state);
        }
    });
    states.forEach((state: string) => {
        const add: ProviderLicenseFromData = { state };
        const stateLicenses = licenses.filter((item) => item.state === state);
        const deasList: FormLicense[] = [];
        const cscList: FormLicense[] = [];
        const cmecList: FormLicense[] = [];
        const practiceList: FormLicense[] = [];
        stateLicenses.forEach((item) => {
            if (item.type === ELicenseType.DEA) {
                deasList.push(LicenseData.serverToForm(item));
            } else if (item.type === ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION) {
                cscList.push(LicenseData.serverToForm(item));
            } else if (item.type === ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES) {
                cmecList.push(LicenseData.serverToForm(item));
            } else if (item.type === ELicenseType.MIC) {
                //just skip, coz mic don't have state
            } else {
                practiceList.push(LicenseData.serverToForm(item));
            }
        });
        if (deasList && deasList.length > 0) {
            add[ELicenseType.DEA] = deasList;
        }
        if (cscList && cscList.length > 0) {
            add[ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION] = cscList;
        }
        if (cmecList && cmecList.length > 0) {
            add[ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES] = cmecList;
        }
        if (practiceList && practiceList.length > 0) {
            add[ELicenseType.PRACTICE] = cmecList;
        }
        ret.push(add);
    });
    return ret;
};

export const getFormDataFromLicense = (licenses: License[], type: ELicenseType): FormLicense | undefined => {
    const target = (licenses || []).find((item) => item.type === type);
    if (!target) {
        return undefined;
    }
    return LicenseData.serverToForm(target);
};

export const transferPhysicianToFormData = (physicians: Physician[]): ProviderPhysicianFromData[] => {
    const states: string[] = [];
    const ret: ProviderPhysicianFromData[] = [];
    physicians?.forEach((item: Physician) => {
        if (!states.includes(item.state)) {
            states.push(item.state);
        }
    });
    states.forEach((state: string) => {
        const add: ProviderPhysicianFromData = { state };
        const physician = physicians.find((item) => item.state === state);
        if (physician) {
            add.collaboratingPhysician = PhysicianData.serverToForm(physician);
        }
        ret.push(add);
    });
    return ret;
};

const initPracticeAddressList = (addressList: Array<PracitceAddressForm> = []) => {
    if (!addressList || addressList.length === 0) {
        return [{
            [AdditionalPracticeFormFieldEnum.ADDRESS]: '',
            [AdditionalPracticeFormFieldEnum.CITY]: '',
            [AdditionalPracticeFormFieldEnum.STATE]: '',
            [AdditionalPracticeFormFieldEnum.ZIP_CODE]: '',
            [AdditionalPracticeFormFieldEnum.IS_DEFAULT]: true,
            [AdditionalPracticeFormFieldEnum.AS_MAILING]: false,
            [AdditionalPracticeFormFieldEnum.NOT_PUBLISH]: false,
            [AdditionalPracticeFormFieldEnum.ADDRESS_PUBLIC_TYPE]: '',
        }];
    }

    if (addressList && addressList.length === 1) {
        return [
            {
                ...addressList[0],
                [AdditionalPracticeFormFieldEnum.IS_DEFAULT]: true,
            },
        ];
    }

    return addressList;
};

export const transferProviderToFormData = (data: any): UniprofileFormData | undefined => {
    if (!data) {
        return undefined;
    }
    let { supportPayType } = data;
    if (!data.supportPayType) {
        if ((data.insuranceList && data.insuranceList.length > 0) || (data.otherInsuranceList && data.otherInsuranceList.length > 0)) {
            supportPayType = INSURANCE_TYPE_CASH_AND_INSURANCE;
        } else {
            supportPayType = INSURANCE_TYPE_CASH;
        }
    }

    // 处理AVA返回的treatment approach
    let treatmentApproach = data.providerPractice?.treatmentApproach || ''
    if (treatmentApproach) {
        if (treatmentApproach[0] !== '•') {
            treatmentApproach = treatmentApproach.replace(/^/, '•');
        }

        treatmentApproach = treatmentApproach.replace(/\r\n/g, '\n•')
    }

    
    return {
        id: data.id,
        assignee: data.assignee,
        notes: data.notes,
        listChannelCount: data.listChannelCount,
        listedChannels: data.channelListedOverView,
        payPlan: data.payPlanRsp,
        conversationId: data.conversationId,
        previousListings: data.previousListings,
        appointmentIntegrationUrl: data.appointmentIntegrationUrl,
        avaAutoReply: data?.avaAutoReply,
        channelAccount: data?.channelAccount,
        channelPassword: data?.channelPassword,
        listingType: data?.listingType,
        documentId: data.documentId,
        deaLicensePrescribeStatus: data.deaLicensePrescribeStatus,
        profile: {
            [ProfileFormFieldEnum.ID]: data.id,
            [ProfileFormFieldEnum.EMAIL]: data.email,
            [ProfileFormFieldEnum.GMAIL]: data.gmail,
            [ProfileFormFieldEnum.GMAIL_PASSWORD]: data.gmailPassword,
            [ProfileFormFieldEnum.TEL]: data?.tel ? data.tel.replace(/-/g, '') : '',
            [ProfileFormFieldEnum.MAILING_ADDRESS]: data.address,
            [ProfileFormFieldEnum.STATE]: data.state,
            [ProfileFormFieldEnum.NPI_NUMBER]: data.npi,
            [ProfileFormFieldEnum.YEARS_OF_EXPERIENCE]: data.yearExp,
            [ProfileFormFieldEnum.SPECIALITYINHEALTH]: data.specialityInHealth?.split(','),
            [ProfileFormFieldEnum.SPECIALITYINFAMILY]: data.specialityInFamily?.split(','),
            [ProfileFormFieldEnum.FIRST_NAME]: data.firstName,
            [ProfileFormFieldEnum.LAST_NAME]: data.lastName,
            [ProfileFormFieldEnum.TITLE]: data.title,
            [ProfileFormFieldEnum.INTRO]: data.intro,
            [ProfileFormFieldEnum.PRIMARY_LANGUAGE]: data.primaryLanguage || 'English',
            [ProfileFormFieldEnum.FOREIGN_LANGUAGE]: data.foreignLanguage ? data.foreignLanguage?.split(',') : undefined,
            [ProfileFormFieldEnum.PROFILE_PHOTO]: data.photoList,
            [ProfileFormFieldEnum.LICENSES]: getNurseLicenseList(data.licenseDtoList),
            [ProfileFormFieldEnum.ADDITIONAL_PHOTO]: data.additionalPhotoList,
            [ProfileFormFieldEnum.STATUS]: data.status,
            [ProfileFormFieldEnum.LIST_STATUS]: data.listStatus,
            [ProfileFormFieldEnum.ALLOW_AUTH]: data.allowAuth,
            [ProfileFormFieldEnum.AUDIT_STATUS]: data.auditStatus,
            [ProfileFormFieldEnum.UPDATE_STATUS]: data.updateStatus,
            [ProfileFormFieldEnum.PHOTO]: data.photo,
            [ProfileFormFieldEnum.CITY]: data.city,
            [ProfileFormFieldEnum.ZIP_CODE]: data.zip,
            [ProfileFormFieldEnum.WEBSITE]: data.website || '',
            [ProfileFormFieldEnum.MIDDLE_NAME]: data.middleName || '',
            [ProfileFormFieldEnum.HEADLINE]: data.headLine || '',
            [ProfileFormFieldEnum.CREDENTIAL]: data.credential?.split(',') || [],
            [ProfileFormFieldEnum.NPI_NOT_REQUIRED]: data.npiNotRequired,
            [ProfileFormFieldEnum.HAS_EHR_ACCOUNT_PWD]: data.hasEhrAccountPwd,
            [ProfileFormFieldEnum.PRODUCT]: data.product,
            [ProfileFormFieldEnum.CHANNEL_PREFERENCE]: data.channelPreference,
            [ProfileFormFieldEnum.SIGN_UP_FROM]: data.signUpFrom,
            headLineList: data.headLineList || [],
        },
        // services: data.providerChannelServiceList,
        services: data?.providerServiceList?.map((service: Service) => {
            const { addressId, personType = '', telehealthType = '' } = service;
            return {
                ...service,
                inpersonChecked: addressId && personType?.length > 0,
                telehealthChecked: telehealthType?.length > 0,
            };
        }) || [],
        practice: {
            [PracticeFormFieldEnum.TREATMENT_APPROACH]: treatmentApproach,
            [PracticeFormFieldEnum.TREATMENT_PHILOSOPHY]: data.providerPractice?.treatmentPhilosophy,
            [PracticeFormFieldEnum.SPECIALITY_IN_FAMILY]: data?.specialityInFamily,
            [PracticeFormFieldEnum.SPECIALITY_IN_HEALTH]: data?.specialityInHealth,
            [AdditionalPracticeFormFieldEnum.CITY]: data.providerPractice?.practiceCity,
            [AdditionalPracticeFormFieldEnum.ADDRESS]: data.providerPractice?.practiceAddress,
            [AdditionalPracticeFormFieldEnum.COMPANY_TYPE]: data.providerPractice?.companyType,
            [AdditionalPracticeFormFieldEnum.DESCRIPTION]: data.providerPractice?.practiceDesc,
            [AdditionalPracticeFormFieldEnum.EMAIL]: data.providerPractice?.practiceEmail,
            [AdditionalPracticeFormFieldEnum.LOGO]: data.providerPractice?.practiceLogo,
            [AdditionalPracticeFormFieldEnum.NAME]: data.providerPractice?.practiceName || (data.firstName && data.lastName ? `${`${data.firstName} ${data.lastName}`}'s Practice` : ''),
            [AdditionalPracticeFormFieldEnum.STATE]: data.providerPractice?.practiceState,
            [AdditionalPracticeFormFieldEnum.TEL]: data.providerPractice?.practicePhone,
            [AdditionalPracticeFormFieldEnum.WEBSITE]: data.providerPractice?.practiceWebsite,
            [AdditionalPracticeFormFieldEnum.ZIP_CODE]: data.providerPractice?.practiceZip,
            [AdditionalPracticeFormFieldEnum.PRACTICE_FRONT_URL]: data.providerPractice?.practiceFrontUrl,
            [AdditionalPracticeFormFieldEnum.KIWI_HEALTH_WEBSITE]: data.providerPractice?.kiwihealthWebsite,
            [AdditionalPracticeFormFieldEnum.SPECIALTY_LIST]: data.providerPractice?.specialtyList || [],
            [AdditionalPracticeFormFieldEnum.CONDITION_TREATED_LIST]: data.providerPractice?.conditionTreatedList || [],
            [AdditionalPracticeFormFieldEnum.NOT_HAVE_NAME]: data.providerPractice?.notHaveName,
            [AdditionalPracticeFormFieldEnum.NOT_HAVE_PHONE]: data.providerPractice?.notHavePhone,
            [PracticeFormFieldEnum.ADDRESSLIST]: initPracticeAddressList(data.providerPractice?.addressList),
            [PracticeFormFieldEnum.SPECIALITY_HIGHLIGHT_LIST]: data.providerPractice?.specialtyHighlightList || [],
            [AdditionalPracticeFormFieldEnum.TAX_ID_TYPE]: data.providerPractice?.taxIdType || 'NONE',
            [AdditionalPracticeFormFieldEnum.TAX_ID]: data.providerPractice?.taxId,
            [AdditionalPracticeFormFieldEnum.SETUPLAPOPTION]: data.providerPractice?.setUpLapOption,
            [AdditionalPracticeFormFieldEnum.W9FORM]: data.providerPractice?.w9Form,
            [AdditionalPracticeFormFieldEnum.VFD_PHONE_LINE]: data.providerPractice?.vfdPhoneLine,
            philosophyConversationId: data.philosophyConversationId || '',
            practiceDescConversationId: data.practiceDescConversationId || '',
        },
        profileLinks: data.profileLinks,
        profileLinkInfo: data.profileLinkInfo,
        experienceList: data.providerExperienceList?.map((experience: ProviderChannelHealthcareExperience) => ({
            ...experience,
            startDate: experience.startDate ? dayjs(experience.startDate) : undefined,
            endDate: experience.endDate ? dayjs(experience.endDate) : undefined,
        })),
        educationList: data.providerEducationList?.map((education: ProviderChannelEducation) => ({
            ...education,
            startDate: education.startDate ? dayjs(education.startDate) : undefined,
            endDate: education.endDate ? dayjs(education.endDate) : undefined,
        })),
        addtional: {
            payout: data.payout,
            showReview: typeof data.showReview !== 'boolean' ? true : data.showReview,
            associationAdditionalList: data.associationAdditionalList,
            awardAdditionalList: data.awardAdditionalList,
            publicationAdditionalList: data.publicationAdditionalList,
        },
        insurance: {
            insuranceList: data.insuranceList,
            otherInsuranceList: data.otherInsuranceList ? data.otherInsuranceList?.join(',') : '',
            supportPayType,
        },
        biographic: {
            ethnicity: data.biographic?.ethnicity,
            gender: data.biographic?.gender,
            birthDate: data.biographic?.birthDate ? dayjs(removeHMSInUTCTime(data.biographic?.birthDate)) : undefined,
            religion: data.biographic?.religion,
            usedName: data.biographic?.usedName,
        },
        licenses: transferLicenseToFormData(data.licenseDtoList),
        licensesInState: transferLicenseToFormDataArray(data.licenseDtoList),
        malpracticeInsuranceCertificate: getFormDataFromLicense(data.licenseDtoList, ELicenseType.MIC),
        physicians: data.physicianList?.map((item: Physician) => PhysicianData.serverToForm(item)),
        seoData: data.seoData,
        containSeoApp: data.containSeoApp,
        source: data.source,
        contractList: data.contractList || [],
    };
};

export const verifyService = (services: ServiceExt[] = []): { errorMessage: string, verifyResult: boolean, services: ICommonFormData[] } => {
    let verifyResult = true;
    let errorMessage = '';
    if (!services) {
        return {
            verifyResult,
            errorMessage,
            services,
        };
    }
    const newServices = services.map((service: ServiceExt) => {
        const verifyData = {
            hasSetPrice: true,
            hasSetMeetingType: true,
        };

        if (service[ServicesFormFieldEnum.SERVICE_TYPE] === 'Initial Visit' && !service.inpersonChecked && !service.telehealthChecked) {
            verifyData.hasSetMeetingType = false;
            verifyResult = false;

            errorMessage = 'Please set the meeting type of the service with a red border';
        }

        if (service[ServiceFormFieldEnum.PRICE] === null) {
            verifyData.hasSetPrice = false;
            verifyResult = false;

            errorMessage = 'Please set the price of the service with a red border';
        }

        return {
            ...service,
            ...verifyData,
        };
    });

    return {
        verifyResult,
        errorMessage,
        services: newServices,
    };
};

// 一般逻辑，值显示没有被隐藏，且被激活的channel
export const filterAvailableChannel = (channels: Channel[] = []) => {
    return channels ? channels.filter((channel: Channel) => channel.isShow && channel.activeStatus === EChannelActiveStatus.ACTIVE) : [];
};

// my channel逻辑，如果channel被隐藏，只显示list的，如果channel没有被激活，则全部不显示
export const filterAvailableChannelForMyChannel = (channels: Channel[] = []) => {
    return channels.filter((channel: Channel) => {
        if (!channel.isShow) {
            if (channel.listStatus === EListStatus.LISTED) {
                return true;
            }
        }

        return channel.activeStatus !== EChannelActiveStatus.ACTIVE;
    });
};
