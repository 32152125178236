import { EnumFields } from './enumerationData';

export enum ChannelPayPlanTypeEnum {
    FREE = 'free',
    PAID = 'paid',
    PREMIUM = 'premium',
    STARTAR = 'starter',
    PLUS = 'plus',
    PRO = 'pro',
}

export type Meta = {
    label: string;
    value?: string | number | number;
};

export type ServerMeta = {
    id: number;
    type: EnumFields | string;
    content: string;
    dictKey: string;
    description?: string | null;
    seq?: number;
    updateAt?: string;
    updateBy?: number;
    createAt?: string;
    createBy?: number;
};

//move to common?
export type License = {
    id?: number;
    providerId?: number;
    type: string;
    secondType?: string;
    state: string;
    licenseNumber: string;
    expireDate?: string;
    schedule?: string;
    url?: string;
    certificatedTitle?: string;
    classTitle?: string;
    hourCompleted?: number;
    licenseType?: string;
};

export type Physician = {
    id: number;
    providerId: number;
    name: string;
    email: string;
    tel: string;
    address: string;
    zip: string;
    state: string;
    npi: string;
    yearExp: number;
    physicianState: string;
    licenseState: string;
    specialities: string;
    effectiveDate: string;
    birthdayDate?: string;
    deaNumber?: string;
    expDate: string;
    url: string;
    choice: number;
};

export enum ELicenseType {
    DEA = 'DEA',
    PA = 'PA',
    MIC = 'MIC',
    NURSE = 'NURSE',
    CONTROLLED_SUBSTAN_CECERTIFICATION = 'CSC', //due to db limit
    CONTINUING_MEDICAL_EDUCATION_CERTIFICATES = 'CMEC',
    PRACTICE = 'PRACTICE', //all others set to PRACTICE license
    AUTONOMY = 'AUTONOMY',
    OTHER = 'OTHER'
}

export enum ERedirect {
    VERIFY_EMAIL = '1',
    RESUME_FROM_EMAIL = '2',
    RESET_PASSWORD = '3',
}

export type GoogleToken = {
    access_token: string;
    expires_in: number;
    scope: string;
    token_type: 'Bearer';
};

export type BaseUserInfo = {
    firstName?: string;
    gmail: string;
    id?: number;
    lastName?: string;
    tel?: string;
};

export type Service = {
    id: number;
    colorId?: string;
    description?: string;
    duration?: number;
    name: string;
    personAddress?: string;
    personCity?: string;
    personState?: string;
    personType?: string;
    personZip?: string;
    providerId?: number;
    sendForm?: string;
    telehealthType?: string;
    type?: string;
    price?: number;
    intakeForm?: string;
    addressId: number | null;
    enableSlidingScale?: boolean;
    lowestPrice?: number;
    highestPrice?: number;
};

export enum EListStatus {
    UNDER_REVIEW = 'under-review',
    REJECTED = 'rejected',
    LISTED = 'listed',
    NOT_LISTED = 'not-listed',
}

export type Channel = {
    id: number;
    name: string;
    logo: string;
    website: string;
    intro: string;
    listStatus: EListStatus;
    currentStep: string;
    channelDesc: string;
    applicationDesc: string;
    avgMonthGainPatient: number;
    estimatedApplicationTime: number;
    channeStepList: string[];
    channelServiceRspList?: Service[];
    //for upcoming using
    initUpvoteCount: number;
    voteCount: number;
    voted: boolean;
    updateTime: string;
    status: string;
    listedCount: number;
    unProcessedCount: number;
    waitingCount: number;
    top: boolean;
    economics?: string,
    news?: string
    caseStudy?: string,
    type: 'Upcoming' | 'Live',
    tagList: string[]
    tags: string | string[],
    payPlanType: string | null,
    kiwiPrice?: number;
    originalPrice?: number;
    billingCycle?: string;
    subscriptionPriceId?: string;
    freeTrialDays?: number;
    payStatus: string;
    nextPaymentDue: string | null;
    removeStatus: string;
    activeStatus?: EChannelActiveStatus;
    isShow?: boolean;
    klarityPlanLevel: EKlarityPlanLevel,
    isKlarityPlan?: boolean,
    locked?: boolean,
};

//channel/upcoming/list
export type ChannelItemBasic = {
    id: number;
    name: string;
    logo: string;
    rectangleLogo?: string;
    website: string;
    updateTime: string;
    status: string;
    listedCount: number;
    unProcessedCount: number;
    waitingCount: number;
    top: false;
    payPlanType?: ChannelPayPlanTypeEnum;
    activeStatus?: EChannelActiveStatus;
    isShow?: boolean;
};

export enum ChannelType {
    UPCOMING = 'Upcoming',
    ALIVE = 'Alive',
}

export enum ChannelActivationStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
}

export enum EChannelServiceConfig {
    NO_LIMIT = 'NO_LIMIT',
    SINGLE_TYPE_MULT_SERVICE = 'SINGLE_TYPE_MULT_SERVICE',
    SINGLE_TYPE_SINGLE_SERVICE = 'SINGLE_TYPE_SINGLE_SERVICE',
}

export enum EChannelActiveStatus {
    ACTIVE = 'A',
    INACTIVE = 'D',
}

export enum EKlarityPlanLevel {
    PREMIUM = 0,
    STARTER = 1,
    PLUS = 2,
    PRO = 3,
}

export type ChannelItem = ChannelItemBasic & {
    intro?: string;
    channelDesc?: string;
    economics?: string,
    news?: string
    caseStudy?: string,
    type: ChannelType,
    tagList?: string[],
    complete: boolean,
    serviceConfig?: EChannelServiceConfig,
    systemEmail?: string,
    payPlanType?: string,
    kiwiPrice?: number;
    originalPrice?: number;
    billingCycle?: string;
    subscriptionPriceId?: string;
    freeTrialDays?: number;
    reviewAvailable?: boolean;
    reviewRanking?: number;
    isShow?: boolean;
    activeStatus: EChannelActiveStatus;
    sameAsKiwi?: boolean,
    klarityPlanLevel: EKlarityPlanLevel,
    klarityChannelDesc?: string,
    klarityEconomics?: string,
    klarityCaseStudy?: string,
    klarityNews: string,
    klarityTagList?: string[],
    klaritySystemEmail?: string,
};

export type IntakeqForm = {
    id: string;
    name: string;
    practitionerId: string;
    archived: boolean;
    anonymous: boolean;
};

export type Review = {
    id: number;
    reviewerName: string;
    reviews: string;
    ratings: number;
    verified: boolean;
    anonymous: boolean;
};

export type ServiceTypeInfo = {
    typeName: string,
    name: string,
    description: string
};

export enum EProspectType {
    PROSPECT = 'Prospect',
    PATIENT = 'Patient',
}

export type Prospect = {
    address?: string;
    billing?: string;
    birthday?: string;
    carrier?: string;
    channel: string;
    channelId: number | string | null;
    city: string;
    email: string;
    firstName: string;
    gender: string;
    lastName: string;
    notes: string;
    plan: string;
    state: string;
    tel: string;
    zip: string;
    idPhoto?: string;
    createTime?: string;
    lastReplyBy?: 'PROVIDER' | 'AI';
    lastReplyAt?: string;
    id: number;
    providerId?: number;
    type: EProspectType;
    idFront: string,
    idBack: string,
    status: EStatus,
    ehr: 'Yes' | 'No',
    preferMethod?: string,
    lastSurveySendStatus?: string,
    lastSurveySendDate?: string,
    lastSendAnswered?: boolean,
    sendCount?: number,
    isRead: number,
};

export enum EEmailStatus {
    SENT = 'SENT',
    INBOX = 'INBOX',
}

export type EmailContentItem = {
    id: string,
    subject: string,
    from: string,
    to: string,
    date: string,
    content: string, //has html
    snippet: string,
    body: string, //from ai, without html
    direction: EEmailStatus,
    aiReply?: string,
    isAiReply: 1 | 0,
    isSendByAi: boolean,
    feedback?: 'like' | 'dislike'
};

export type ProspectNotesItem = {
    id: number,
    prospectId: number,
    notes: string,
    createTime: string,
};

export enum EStatus {
    Active = 'Active',
    Archived = 'Archived',
}

export enum EStatusAction {
    Activate = 'Activate', //Active
    Archive = 'Archive', //Archived

}

export type HomeConfig = {
    id?: number,
    title: string,
    headLine: string,
    content: string,
};

export type CityState = {
    city: string,
    state?: string,
};

export type SelectValue = {
    label: string;
    value: string;
    disabled?: boolean;
};

export type UrlString = `http${string}`;

export enum PasswordError {
    LOWERCASE = 'lowercase',
    SPECIAL = 'special',
    UPPERCASE = 'uppercase',
    MINIMUM = 'minimum',
    NUMBER = 'number',
}

export type LocalSEOInfo = {
    state: string;
    landmarks?: string[];
    cities?: string[];
    counties?: string[];
    zip?: string[]
};

export type LocalSEOInfoServer = {
    landmark?: string[];
    city?: string[];
    country?: string[];
    zip?: string[],
};

export type EditLocalSEOInfoServer = {
    landmark?: string;
    city?: string;
    country?: string;
    zip?: string,
};

export enum EServiceType {
    INIT = 'New patient initial visit',
    CONSULTATION = 'Consultation',
    ESTABLISHED_PATIENT_VISIT = 'Established patient visit',
    MEDICATION_REFILL = 'Medication refill',
    OTHER_PATIENT_SUPPORT = 'Other patient support',

    CONSULTATION_OVERCHARGE = 'Consultation overcharge',
}

export enum EScroeType {
    TERRIBLE = 1,
    BAD = 2,
    OKAY = 3,
    GOOD = 4,
    AMAZING = 5,
}

export type PatientsFeedback = {
    id?: number;
    firstName?: string;
    lastName?: string;
    answerAt: number;
    overallScore: EScroeType;
    email?: string;
    feedbackContent: string;
    isReplied?: boolean;
    patientId: number;
};

export type KlarityServiceTypeInfo = {
    rowId?: number;
    state: ServerMeta,
    serviceId: number,
    serviceType: string,
    customizedServiceName: string,
    earning: number,
    patientCost: number,
    contactType: EContactType[],
    groupAndLimitationId: number,
    qtyPatientGroup: number,
    qtyLimitation: number,
    accpet?: string[],
    limitations?: string[],
    allPatientGroup?: boolean,
    avaPriceInsight?: number,
};

export type KlarityServiceTypeTemplate = {
    id: number,
    iconUri: string,
    serviceType: EServiceType,
    displayName: string,
    description: string,
    mandatory: boolean,
    newPatient: boolean,
    teleHealth: boolean,
    inPerson: boolean,
    defaultDuration: number,
    minDuration: number,
    maxDuration: number,
    switchPatientGroup: boolean,
    qtyPatientGroup: number,
    switchLimitation: boolean,
    qtyLimitation: number,
    createAt: string, //'2024-04-30T08:53:19.000+00:00',
    createBy: number,
    updateAt: string, //'2024-05-01T13:27:08.000+00:00',
    updateBy: number,
    klarityServiceTypePatientGroupList?: IKlarityServiceTypePatientGroup[],
    klarityServiceTypeLimitationList?: IKlarityServiceTypeLimitation[],
    switchPatientGroupLimitation: boolean,
    switchTeleHealthInPerson: boolean,
};

export type KlarityServiceTypeValue = {
    patientGroupIds: string; //"1, 2"
    limitationIds: string; //"1, 2"
    serviceTypeId: number,
    duration: number,
    id: number,
    mandatory: boolean,
    specId: number,
    teleHealth: boolean,
    inPerson: boolean,
    createAt: string, //'2024-05-07T09:17:17.000+00:00',
    createBy: number,
    updateAt: string, //'2024-05-07T09:17:17.000+00:00',
    updateBy: number,
    switchType: boolean,
    switchPatientGroupLimitation: boolean,
    switchTeleHealthInPerson: boolean,
};

export type KlarityService = {
    id: number,
    iconUrl: string,
    iconUri: string,
    specialtyName: string,
    description: string,
    commonCondition: string,
    createAt: string,
    createBy: number,
    updateAt: string, //'2024-04-30T12:36:13.000+00:00',
    updateBy: number,
    recommended: boolean,
    have?: boolean,
    serversTypes?: KlarityServiceTypeValue[],
    serversTypeNames?: KlarityServiceTypeTemplate[],
    savedValue?: ServerKlarityService[], //only use for FE

    // name: string;
    // toolsTips?: string;
    // conditions?: ServerMeta[];
    // licenseStates: ServerMeta[];
    // serviceType: string[];
    // duration?: number;
    // colorId?: string;
    // personAddress?: string;
    // personCity?: string;
    // personState?: string;
    // personType?: string;
    // personZip?: string;
    // providerId?: number;
    // sendForm?: string;
    // telehealthType?: string;
    // type?:string;
    // price?:number;
    // intakeForm?: string;
    // addressId?: number | null;
    // enableSlidingScale?: boolean;
    // lowestPrice?: number;
    // highestPrice?: number;
    // serviceTypeInfo?: KlarityServiceTypeInfo[];
};

export type KlarityServicePrice = {
    id: number,
    state: string //'California',
    specialty: string //'Psychiatric & Mental Health',
    licensureLevel: 'A' | 'B',
    configType: EServiceConfigPriceType,
    newPatientInitialVisit: number,
    establishedPatientFollowUpVisit: number,
    medicationRefill: number,
    documentationSupport: number,
    consultation: number,
    createAt: string, //'2024-04-28T16:19:55',
    updateAt: string, //'2024-04-28T16:19:55'
};

export type TKlarityPrice = {
    krc?: number,
    recommendPriceArea?: [number, number, number, number],
    mmu?: number,
    pf?: number,
};

export type TServiceStateItem = {
    itemId?: number,
    state: string, //'CA',
    duration: number,
    earning: number,
    marketingMarkup: number,
    platformFee: number,
    patientCost: number,
    practiceAddressIds: string[], //['1','2','3',],
    teleHealth: boolean,
    inPerson: boolean
};

export type TPatientGroupLimitation = {
    id?: number,
    stateGroup: string[], // ['NY','CA',],
    patientGroup: string[], // [ '123','456','789',],
    limitation: string[], //['123123','456456',],
    appliedTeleHealth: boolean,
    appliedInPerson: boolean
};

export type ServerKlarityService = {
    serviceId: number,
    serviceSpecialtyName: string,
    serviceSpecialtyId: string,
    serviceTypeId: number,
    serviceType: string,
    providerId: number,
    customizedServiceName?: string,
    description: string,
    priceUpdateAt: string, //"2024-05-10T14:59:00"
    disableFlag: boolean,
    serviceStateItems: TServiceStateItem[],
    patientGroupLimitations: TPatientGroupLimitation[],

};

export type TKlarityStateTPrice = {
    [EServiceType.INIT]: TKlarityPrice,
    [EServiceType.CONSULTATION]: TKlarityPrice,
    [EServiceType.ESTABLISHED_PATIENT_VISIT]: TKlarityPrice,
    [EServiceType.MEDICATION_REFILL]: TKlarityPrice,
    [EServiceType.OTHER_PATIENT_SUPPORT]: TKlarityPrice,
    [EServiceType.CONSULTATION_OVERCHARGE]: TKlarityPrice,
};

export type TUniprofileFlag = {
    kiwiServiceFlag: boolean,
    klarityServiceFlag: boolean
};

export enum AddressPublicType {
    WALK_IN = 'Walk-in',
    INVITATION = 'Invitation',
    PRIVATE = 'Private',
}

export enum EContactType {
    TELE_HEALTH = 'Tele-health',
    IN_PERSON = 'In person',
}

export enum EServiceConfigPriceType {
    KRC = 'KRC',
    PLATFORM_FEE = 'PF',
    MARKETING_MARKUP = 'MMU',
}

export type TConfigTypePrice = {
    [EServiceConfigPriceType.KRC]?: KlarityServicePrice[],
    [EServiceConfigPriceType.MARKETING_MARKUP]?: KlarityServicePrice[],
    [EServiceConfigPriceType.PLATFORM_FEE]?: KlarityServicePrice[],
};

export type TKlarityServiceServerTableItem = {
    serviceId: number,
    serviceSpecialtyName: string,
    serviceSpecialtyId: number,
    serviceTypeId: number,
    serviceType: EServiceType,
    displayName: string
    providerId: number,
    customizedServiceName?: string,
    state: string//'NY',
    duration: number,
    earning: number,
    patientCost: number,
    teleHealth: boolean,
    inPerson: boolean,
    appliedTeleHealth: boolean,
    appliedInPerson: boolean,
    groupAndLimitationId: number,
    qtyPatientGroup: number,
    qtyLimitation: number,
    allPatientGroup: boolean,
    avaPriceInsight?: number,
};

export type TKlarityServiceUiTableItem = {
    serviceSpecialtyName: string,
    serviceSpecialtyId: number,
    serviceTypeInfo?: KlarityServiceTypeInfo[],
};

export interface IKlarityServiceTypePatientGroup {
    id?: number;
    presetId?: number;
    serviceTypeId: number;
    patientGroup: string;
    sequence: number;
    type: string;
    notDelete: boolean;
}

export interface IKlarityServiceTypeLimitation {
    id?: number;
    presetId?: number;
    serviceTypeId: number;
    limitation: string;
    sequence: number;
    type: string;
    notDelete: boolean;
}

export enum EAppointmentContactTypeDictKey {
    VIDEO = 'video',
    IN_PERSON = 'in-person',
}

export type TAppointment = {
    id: string;
    name: string;
    available_contact_types: EAppointmentContactTypeDictKey[];
    length: number;
    required_appointment_type: boolean;
};

export type TPackage = {
    providerId: number;
    ehrProviderId: number;
    intakeqProviderId: string;
    serviceId: string;
    intakeqLocationIdList: null;
    offeringId: string;
    name: string;
    length: number;
    price: number;
    url: string;
    type: EAppointmentContactTypeDictKey[];
    state: string[];
    serviceType: string;
    specialty: string;
    ehrPackageName: string;
    requiredAppointmentType: boolean;
    offeringIncludes: TAppointment[];
    customizedServiceName?: string;
};

export type TProfileLinkInfo = {
    profileLink?: string,
    vfdLink?: string,
};

export type TAirtableProvider = {
    providerId: string, //'652d87df7b4996568e2b5188',
    service?: TPackage[],
    availabilities: unknown,
    appointmentUpdateAt: string, // '2024-05-31T02:06:24.670+00:00',
    id: string, // '652d87df7b4996568e2b5188',
    source: 'A' | 'E',
    firstName: string, // 'Stephanie',
    middleName: string, // '',
    lastName: string, // 'Nazaire',
    headline: string, //'Stephanie Nazaire, FNP-BC',
    years: number,
    state?: string[],
    defaultAddressLat: string,
    defaultAddressLng: string,
    city?: string,
    specialty: string[], // ['Mental Health'],
    condition: string[], // ['Anxiety','Depression',],
    credential: string, // 'FNP-BC',
    photos: string[],
    supportPayType: string[], // ['CASH',],
    insurance?: unknown,
    sortPrice?: unknown,
    signUpDate: string, // '2023-01-06T00:00:00.000+00:00',
    patientRating: string, // '0',
    listingStatus: string, // 'L',
    slug?: string, // 'stephanie-nazaire',
    updateAt: string, // '2024-05-31T02:00:41.758+00:00',
    profileLinks: string[], // ['https://dev.d2ysjydukc4efk.amplifyapp.com/provider/stephanie-nazaire?state=NY',]
    profileLinkInfo?: TProfileLinkInfo[],
};

export type TSelfEPrescription = {
    id?: number,
    providerId?: number,
    assistantName: string, //'test',
    assistantEmail: string, //'111@qq.com.demo',
    createAt?: string, //'2024-06-13T10:51:30.000+00:00',
    createBy?: number,
    updateAt?: string, // '2024-06-14T01:59:39.000+00:00',
    updateBy?: null | number,
    productAdded?: boolean,
    productPurchased?: boolean,
    productLimitAdded?: boolean
};

export type TAssistant = {
    id?: number,
    providerId?: number,
    assistantName: string, //'test',
    assistantEmail: string, //'111@qq.com.demo',
    createAt?: string, //'2024-06-13T10:51:30.000+00:00',
    createBy?: number,
    updateAt?: string, // '2024-06-14T01:59:39.000+00:00',
    updateBy?: null | number,
    productAdded?: boolean,
    productPurchased?: boolean,
    productLimitAdded?: boolean
};

export type TCollaboratingPhysician = {
    id?: number,
    providerId: number,
    choice: number,
    name: string,
    email: string,
    tel: string,
    address: string,
    zip: string,
    state: string, //'AZ',
    physicianState: string, // 'AK',
    npi: string,
    yearExp: number | null,
    licenseState: string,
    specialities: string, //'specialities1',
    effectiveDate: string, // '2023-08-17T00:00:00.000+00:00',
    expDate: string, // '2023-11-23T00:00:00.000+00:00',
    url: string, //  'https://saas-dev-jp.s3.ap-northeast-1.amazonaws.com/dev/6ced5c1c-9df7-4857-af90-805f3bfffaf8.png'
    deaNumber?: string,
    birthdayDate?: string, // '2023-08-17',
    productAdded?: boolean, //add to cart
    productPurchased?: boolean, //Purchased
    productLimitAdded?: boolean
};
export interface IStripePaymentInfo {
    card: {
        brand?: string;
        last4?: string;
    },
    id: string;
}

export enum EStepTypes {
    KLARITY_STARTER_SET_UP = 'KLARITY_STARTER_SET_UP',
    EHR_SET_UP = 'EHR_SET_UP',
}

export type TUtmInfo = {
    source?: string,
    medium?: string,
    campaign?: string,
};

export enum EDISPUTE_STATUS {
    VALID = 'VALID',
    INVALID = 'INVALID',
    NORMAL = 'NORMAL',
    INVESTIGATION = 'INVESTIGATION',
}
